import { Document, Page, pdfjs } from 'react-pdf';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Sentry } from '@idk-web/core-utils';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Box, Pagination, useWindowSize } from '@idk-web/core-ui';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

export type PdfAttachmentProps = {
  file: string | Blob;
};

const PdfAttachment: FC<PdfAttachmentProps> = ({ file }) => {
  const windowSize = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState(1);
  const [documentWidth, setDocumentWidth] = useState<number | undefined>(
    undefined,
  );
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (ref.current) {
      setDocumentWidth(ref.current.offsetWidth);
    }
  }, [windowSize]);

  return (
    <>
      <Document
        file={file}
        inputRef={ref}
        onLoadSuccess={(e) => {
          setNumPages(e.numPages);
        }}
        onLoadError={(e) => {
          return Sentry.reportError('Failed to load PDF', { error: e });
        }}
      >
        <Page pageNumber={pageNumber} width={documentWidth} />
      </Document>
      {numPages > 1 && (
        <Box alignX="center" style={{ marginTop: 24 }}>
          <Pagination
            page={pageNumber}
            onChange={(page) => setPageNumber(page)}
            count={numPages}
          />
        </Box>
      )}
    </>
  );
};

export default PdfAttachment;
