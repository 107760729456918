import styled from 'styled-components';
import React, { FC } from 'react';
import { ResponsiveImage } from '@idk-web/core-ui';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export type ImageAttachmentProps = {
  src: string;
  alt: string;
};

const ImageAttachment: FC<ImageAttachmentProps> = ({ src, alt }) => {
  return (
    <Container>
      <ResponsiveImage src={src} alt={alt} />
    </Container>
  );
};

export default ImageAttachment;
