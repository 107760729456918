import { useEffect, useMemo, useRef, useState } from 'react';
import { downloadBlob, useIsMounted } from '@idk-web/core-ui';
import { getInboxAttachment } from '@idk-web/api';
import { InboxMessageWithSigners } from '@/components/inbox/safemail/SafeMailInbox';

export type Attachment = {
  state: 'LOADING' | 'LOADED' | 'ERROR';
  error?: string;
  asBlob(): Blob | null;
  asURL(): string | null;
  download(): void;
};

export function useAttachment(
  message: InboxMessageWithSigners,
  token: string | null,
): Attachment {
  const isMounted = useIsMounted();
  const promiseRef = useRef<Promise<Blob> | null>(null);
  const [state, setState] = useState<Attachment['state']>('LOADING');
  const [error, setError] = useState<string | undefined>(undefined);
  const [attachment, setAttachment] = useState<Blob | null>(null);
  const [url, setURL] = useState<string | null>(null);

  useEffect(() => {
    promiseRef.current = null;
    setError(undefined);
    setAttachment(null);

    const controller = new AbortController();

    void load(controller.signal);

    return () => controller.abort();
  }, [message]);

  useEffect(() => {
    if (attachment) {
      setURL(URL.createObjectURL(attachment));

      return () => {
        if (url) {
          URL.revokeObjectURL(url);
          setURL(null);
        }
      };
    }
  }, [attachment]);

  const asBlob = () => attachment;

  const asURL = () => url;

  const load = async (signal?: AbortSignal): Promise<Blob | null> => {
    if (!message.attachmentPath) {
      return Promise.resolve(null);
    }

    setState('LOADING');

    const promise = getInboxAttachment(message.id, token, signal);

    promise
      .then((blob) => {
        if (isMounted() && !signal?.aborted) {
          setState('LOADED');
          setAttachment(blob);
        }
      })
      .catch((e) => {
        if (isMounted() && !signal?.aborted) {
          setState('ERROR');
          setError(e);
        }
      });

    promiseRef.current = promise;

    return promise;
  };

  const download = async () => {
    let fileName: string;

    if (message.zipCreated) {
      fileName = 'signature.zip';
    } else if (message.attachmentName) {
      fileName = message.attachmentName;
    } else {
      fileName = 'attachment';
    }

    const attachment = await load();

    if (attachment) {
      downloadBlob(attachment, fileName);
    }
  };

  return useMemo(
    () => ({
      state,
      error,
      asBlob,
      asURL,
      download,
    }),
    [state, error, attachment, url],
  );
}
